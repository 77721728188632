import React, { forwardRef, useState } from "react";
// import DatePicker from "react-date-picker";

// import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { useHistory } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./reserve-block.css";
import calendarIcon from "./../../Assets/Images/calendar-icon.png";
import warningIcon from "./../../Assets/Images/warning.png";

import { firebase } from "../../config/firebase";

import Loader from "../Loader/loader";
// import { Cookie } from "@mui/icons-material";

export default function ReserveBlock(props) {
  const defaultDate = new Date(props.commonState.currentDate);
  // const defaultDate = new Date(2022,5,1);
  // defaultDate.setDate(defaultDate.getDate() + 1)

  // const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [noOfSeats, setNoOfSeats] = useState("");
  const [regexp] = useState(/^[0-9\b]+$/);
  // const [disableSelectBlock, setDisableSelectBlock] = useState(false)
  const [teamSize, setTeamSize] = useState();
  const [loading, setLoading] = useState(false);

  // const temp_min_date = new Date(2022,7,1);
  // const temp_min_date = new Date(props.commonState.site_data.booking_calendar_from.toDate());

  // const minDate = temp_min_date > defaultDate ? temp_min_date : defaultDate;
  const minDate = props.booking_type === 'Normal Booking' ? defaultDate : new Date(props.commonState.calendar_data.min_date)
  // console.log(minDate)
  const [selectedDate, setSelectedDate] = useState(minDate);
  const maxDate = props.booking_type === 'Normal Booking' ? minDate : new Date(props.commonState.site_data.booking_calendar_to.toDate());
  const history = useHistory();

  const [exclude_dates, setExcludeDates] = useState(props.commonState.calendar_data.exclude_dates)
  const [include_dates, setIncludeDates] = useState(props.commonState.calendar_data.include_dates)
  const [include_dates_only, setIncludeDatesOnly] = useState(props.commonState.calendar_data.include_dates_only)

  let today = new Date()
  let next_day = new Date()
  next_day.setDate(today.getDate() + 1)



  const button_text = props.booking_type === 'Normal Booking' ? 'Book a' : 'Reserve'

  // const [startDate, setStartDate] = useState(minDate);
  // const [endDate, setEndDate] = useState(new Date(props.commonState.site_data.booking_calendar_to.toDate()));
  
 
  // const special_booking_arr = [today, next_day]
  // const special_days_arr = []

  // const highlightWithRanges = [
  //   {
  //     "calendar-highlight-3": special_booking_arr
  //   },
  //   {
  //     "calendar-highlight-3": [
  //       // new Date("2022/12/06"),
  //     ],
  //   },
  // ];

  if (props.booking_type === "Block Booking") {
    maxDate.setDate(
      maxDate.getDate() +
        props.commonState.site_data.advance_booking_period_blockAdmin
    );

  } 
  // else {
  //   maxDate.setDate(
  //     maxDate.getDate() +
  //       props.commonState.site_data.advance_booking_period_individual
  //   );
  // }

  const users_ref = firebase.firestore().collection("Users");
 
  // const block_booking_ref = firebase.firestore().collection('BlockBookings');
  const block_booking_ref = firebase
    .firestore()
    .collection("BlockBookings/" + props.commonState.site + "/BlockBookings");
  const seat_booking_ref = firebase
    .firestore()
    .collection("SeatBookings/" + props.commonState.site + "/SeatBookings");

  React.useEffect(() => {
    if(props.commonState.site_data.block_booking_team_size) {
      users_ref
      .where("blockAdminEmail", "==", props.commonState.userEmail)
      .where("site", "==", props.commonState.site)
      .get()
      .then((querySnapshot) => {
        // console.log("querySnapshot");
        // console.log(querySnapshot.docs.length);
        setTeamSize(querySnapshot.docs.length);
      });
    } else {
      setTeamSize(100);
    }

  }, []);

  const confirmDiallog = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui confirm_dialog">
            <div className="confirm_title">Validation</div>
            <div className="confirm_msg">
              <div className="img">
                <img
                  alt=""
                  style={{ width: 50, height: 50 }}
                  src={warningIcon}
                />
              </div>
              <div className="msg">{msg}</div>
            </div>

            <div className="confirm_buttons">
              <button className="confirm_yes" onClick={onClose}>
                OK
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const SelectBlockClicked = (e) => {
    setLoading(true);
    let validationFlag = false;
    const block_booking_email = props.commonState.isBlockAdmin
      ? props.commonState.userEmail
      : props.commonState.blockAdminEmail;

    exclude_dates.forEach((dt) => {
      if (selectedDate >= dt.start && selectedDate <= dt.end) {
        confirmDiallog("Block Booking is not enabled for today");
        validationFlag = true;
        e.preventDefault();
      }
    })


    block_booking_ref
      .where("blocked_by_email", "==", block_booking_email)
      .where("blocked_date", "==", selectedDate)
      .get()
      .then((querySnapshot) => {
        if (!selectedDate) {
          confirmDiallog("Please select valid date");
          // alert('Please select valid date')
          validationFlag = true;
          e.preventDefault();
        } else if (!noOfSeats || noOfSeats <= 0) {
          confirmDiallog("Please enter valid number of seats");
          // alert('Please enter valid number of seats')
          setNoOfSeats(0);
          validationFlag = true;
          e.preventDefault();
        } else if (querySnapshot.docs.length > 0) {
          confirmDiallog(
            "You already have booking for the selected date. Please select another date."
          );
          validationFlag = true;
          e.preventDefault();
        } else if (noOfSeats > teamSize) {
          confirmDiallog(
            // "You cannot reserve block larger than your team size (" +
            //   teamSize +
            //   ")"
              "You cannot reserve block larger than " +      teamSize  
          );
          // alert('You cannot reserve block larger than your team size ('+ teamSize +')')
          validationFlag = true;
          e.preventDefault();
        }

        if (validationFlag) {
          history.push("/");
        } else {

          let temp_selectedDate  = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate()
          );

          history.push("/floorplan", {
            action: "block_booking",
            commonState: props.commonState,
            // selectedDate: selectedDate,
            selectedDate: temp_selectedDate,            
            noOfSeats: noOfSeats,
            //  floorState: props.floorState
          });
        }

        setLoading(false);
      });
  };

  const ReserveSeatClicked = (e) => {
    setLoading(true);
    let validationFlag = false;


    if (!selectedDate) {
      validationFlag = true;
      setLoading(false);
      confirmDiallog("Please select valid date");
      e.preventDefault();
    } else {

    seat_booking_ref
      .where("blocked_date", "==", selectedDate)
      .where("booked_by_email", "==", props.commonState.userEmail)
      .get()
      .then((querySnapshot) => {

        let selectedMonday;

        if ( selectedDate.getDay() !== 1 )
        {
            let temp_selectedDate  = new Date(selectedDate);
            if(selectedDate.getDay() === 0){
                temp_selectedDate.setDate(temp_selectedDate.getDate() - 6)
            }
            else {
                temp_selectedDate.setDate(temp_selectedDate.getDate() - (temp_selectedDate.getDay() - 1))
            }
            selectedMonday  = new Date(temp_selectedDate);
        }
        else {
            selectedMonday  = new Date(selectedDate);
        }
        
        // console.log('selectedMonday', selectedMonday.getDate())


        //get the week number of selected week
        //Week number will be calcaulated from 03-Jan-2021
        let selectedWeekNo;
        var firstWeekMonday = new Date("03/01/2021");
       
        let Difference_In_Time = selectedMonday.getTime() - firstWeekMonday.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        selectedWeekNo = Difference_In_Days / 7;
        selectedWeekNo = parseInt(selectedWeekNo);
        let week_validation_flag = true;
        let cat_starting_week_no = parseInt(props.commonState.cat_data.week_preference.startingWeek);
        let cat_week_freq = parseInt(props.commonState.cat_data.week_preference.weekFrequency);
        if(selectedWeekNo >= cat_starting_week_no) {
          let cat_starting_week_no_temp = cat_starting_week_no
          while (cat_starting_week_no_temp <= selectedWeekNo ){
            if (selectedWeekNo === cat_starting_week_no_temp) {
              week_validation_flag = false;
            }
            cat_starting_week_no_temp = cat_starting_week_no_temp + cat_week_freq;
          }
        }

        let temp_selectedDate  = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        );

        // let booking_date = new Date(selectedDate)
        let booking_date = new Date(temp_selectedDate)
        let nxtBookingDt = new Date(props.nextBookingDate)


        // Profile - Health Declaration -- all or some unanswered questions
        if(props.profile_health_new){
          validationFlag = true;
          setLoading(false);
          let confirmDialogMsg = "Please update and complete the Health Declaration in your user Profile";
          confirmDiallog(confirmDialogMsg);
          e.preventDefault();
        }      
        
        // Profile - Health Declaration -- all or some negative answers
        else if(props.profile_health_negative){
          validationFlag = true;
          setLoading(false);
          let confirmDialogMsg = "Your health status is not safe to visit office as per your health declaration in your user profile. Booking is hence restricted.";
          confirmDiallog(confirmDialogMsg);
          e.preventDefault();
        }      

        else if(week_validation_flag && props.booking_type != 'Normal Booking') {
          validationFlag = true;
          setLoading(false);
          // let confirmDialogMsg = "Selected date do not fall in the reserved week of your category. Please use 'Special Booking' in case you need to do unplanned booking.";
          let confirmDialogMsg = "Selected date does not fall in the reserved week of your organization/category";
          confirmDiallog(confirmDialogMsg);
          e.preventDefault();
        }

        else if(booking_date < nxtBookingDt){
            validationFlag = true;
            setLoading(false);
            let confirmDialogMsg = "You can book a seat once in " + props.commonState.site_data.bookingFrequency + " days.";
            confirmDiallog(confirmDialogMsg);
            e.preventDefault();
          }        

        else if (querySnapshot.docs.length > 0) {
          setLoading(false);
          confirmDiallog("You already have booking for the selected date.");
          validationFlag = true;
          e.preventDefault();
        }

        let temp_booking_type = props.booking_type;

        if (validationFlag) {
          history.push("/");
        } else {
          if (temp_booking_type === 'Normal Booking') {
            let temp_inc_arr = props.commonState.calendar_data.include_dates_only;
            temp_inc_arr.forEach((dt) => {
              if (selectedDate >= dt.start && selectedDate <= dt.end){
                temp_booking_type = 'special_booking_date'
              }
            })
            if (temp_booking_type !== 'special_booking_date'){
              temp_booking_type = 'special_booking';
            }

            let special_booking_status = 'Can book';
            props.seatBookings.map((item) => {
              let SelDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate()
              );
              let itemDate = new Date(
                item.blocked_date.getFullYear(),
                item.blocked_date.getMonth(),
                item.blocked_date.getDate()
              );

              if (
                SelDate.getTime() === itemDate.getTime()
              ) {
                // console.log('ereh')
                if (item.booking_status === 'Confirmed') {
                  return (special_booking_status =
                    'You already have a booking for today.');
                } else if (item.seats_blocked > item.seats_confirmed) {
                  return (special_booking_status =
                    'You have block booking by your Block Admin. Please select seat from the block.');
                } 
              }
              return true;
            });
        
            const min_date = new Date(props.commonState.site_data.booking_calendar_from.toDate());
            const maxDate = new Date(props.commonState.site_data.booking_calendar_to.toDate());
        
            if (special_booking_status === 'Can book') {
              if(props.commonState.currentDate < min_date || props.commonState.currentDate > maxDate){
                special_booking_status = "Special Booking is not enabled for today";
              } else if (props.commonState.site_data.need_declaration_profile) {
                // Profile - Health Declaration -- new
                if (props.profile_health_new) {
                  special_booking_status = "Please update and complete the Health Declaration in your user Profile";
                }
          
                // Profile - Health Declaration -- all or some negative answers
                else if (props.profile_health_negative) {
                  special_booking_status = "Your health status is not safe to visit office as per your health declaration in your user profile. Special Booking is hence restricted.";
                }
              }
              exclude_dates.forEach((dt) => {
                if (selectedDate >= dt.start && selectedDate <= dt.end){
                  special_booking_status = "Special Booking is not enabled for today";
                }
              })
              if (selectedDate.getDay() === 6 || selectedDate.getDay() === 0 ) { //Weekend
                special_booking_status = "Special Booking is not enabled for today";
              }
            }            
            if (special_booking_status === 'Can book') {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui confirm_dialog">
                    <div className="confirm_title">Confirm!</div>
                    <div className="confirm_msg">
                      Are you sure you want to book a seat?
                    </div>
                    <div className="confirm_buttons">
                      <button className="confirm_no" onClick={onClose}>
                        No
                      </button>
                      <button
                        className="confirm_yes"
                        onClick={() => {
                          onClose();
                          history.push(
                            props.commonState.site_data.need_declaration && (props.health === 'Not Updated' || props.health === 'not safe')
                              ? "/declaration"
                              : "/floorplan",
                            {
                              action: temp_booking_type,
                              commonState: props.commonState,
                              // selectedDate: selectedDate,
                              selectedDate: temp_selectedDate,
                              noOfSeats: noOfSeats,
                            }
                          );
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                );
              },
            });
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui confirm_dialog">
                    <div className="confirm_title">Validation!</div>
                    <div className="confirm_msg">{special_booking_status}</div>
                    <div className="confirm_buttons">
                      <button className="confirm_yes" onClick={onClose}>
                        OK
                      </button>
                    </div>
                  </div>
                );
              },
            });
          } 
          }
          else {
            history.push(
              props.commonState.site_data.need_declaration && (props.health === 'Not Updated' || props.health === 'not safe')
                ? "/declaration"
                : "/floorplan",
              {
                action: props.booking_type,
                commonState: props.commonState,
                // selectedDate: selectedDate,
                selectedDate: temp_selectedDate,
                noOfSeats: noOfSeats,
              }
            );
          }
        }
        setLoading(false);
      });
    }
  };

  const onNumberChange = (e) => {
    const seats = e.target.value;
    // console.log(seats);
    if (seats === "" || regexp.test(seats)) {
      setNoOfSeats(seats);
    } else {
      e.preventDefault();
      // console.log("Invalid Number");
    }
  };

  const seat_number_input = () => {
    if (props.booking_type === "Block Booking") {
      return (
        <div className="reserve-input-teamsize" data-teamsize={teamSize}>
          <input
            placeholder="No. of Seats"
            type="number"
            onChange={onNumberChange}
            className="field-style"
            value={noOfSeats}
          ></input>
          {/* <div>Team Size: {teamSize}</div> */}
         {/* {teamSize} */}
        </div>
      );
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const display_date_picker = () => {
    if (props.booking_type === "Block Booking") {
      exclude_dates.push(...include_dates_only)
      return (
        <DatePicker
          onChange={(value) => setSelectedDate(value)}
          selected={selectedDate}
          maxDate={maxDate}
          minDate={minDate}
          excludeDateIntervals={exclude_dates}
          dateFormat="dd-MMM-y"
          popperPlacement="bottom"
          customInput={<ExampleCustomInput />}
          disabledKeyboardNavigation
          dayClassName={() => "example-datepicker-day-class"}
          popperClassName="example-datepicker-class"
          calendarIcon={<CalendarIcon />}

        />
      );
    } else {
      return (
      <DatePicker
            onChange={(value) => setSelectedDate(value)}
            selected={selectedDate}
            includeDateIntervals={include_dates}
            excludeDateIntervals={exclude_dates}
            dateFormat="dd-MMM-y"
            popperPlacement="bottom"
            customInput={<ExampleCustomInput />}
            disabledKeyboardNavigation
            dayClassName={() => "example-datepicker-day-class"}
            popperClassName="example-datepicker-class"
            calendarIcon={<CalendarIcon />}
          />
      );
    }
  }

  return (
    <div className="reserve-block">
      <Loader loading={loading}></Loader>
      <div className="reserve-block-items">
        <div className="reserve-input-date">
          {/* <input className='field-style'></input> */}
          {/* <DatePicker
            onChange={(value) => setSelectedDate(value)}
            value={selectedDate}
            maxDate={maxDate}
            minDate={minDate}
            format="dd-MMM-y"
            calendarIcon={<CalendarIcon />}
          /> */}
          {display_date_picker()}
        </div>

        {seat_number_input()}

        <div className="reserve-input">
          <button
            onClick={(e) =>
              props.booking_type === "Block Booking"
                ? SelectBlockClicked(e)
                : ReserveSeatClicked(e)
            }
            className="field-style reserve-button"
          >
            {props.booking_type === "Block Booking"
              ? "Select Block"
              // : "Reserve Seat"}
              : props.commonState.cubicleBookingAccess ? `${button_text} Seat/Cubicle` : `${button_text} Seat`}
          </button>
        </div>
      </div>
  
    </div>
  );
}

const CalendarIcon = () => {
  return <img alt="" style={{ width: 30, height: 30 }} src={calendarIcon} />;
};
