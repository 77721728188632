import firebase from 'firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';


const config = {
  apiKey: 'AIzaSyAK4zrwhehbVo2uaD87xX2-aPBWUghnSwc',
  authDomain: 'cp-gsso-test-smartseat.firebaseapp.com',
  projectId: 'cp-gsso-test-smartseat',
  storageBucket: 'cp-gsso-test-smartseat.appspot.com',
  messagingSenderId: '498446112092',
  appId: '1:498446112092:web:8b7e70c0faa3ea40209d99',
  measurementId: 'G-P0V0CE5FRF',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  });
  firebase.firestore().enablePersistence()
  .catch((err) => {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          // console.log(err)
      } else if (err.code === 'unimplemented') {
          // console.log(err)
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });
 

// Subsequent queries will use persistence, if it was enabled successfully
}

export {firebase};
